<template>
  <div id="fast-comment" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(submit)">
              <SdInput
                type="text"
                rules="required"
                label="Low Mark"
                v-model="fastComment.lowMark"
                placeholder="Enter a low mark"
              />

              <SdInput
                type="text"
                rules="required"
                label="High Mark"
                v-model="fastComment.highMark"
                placeholder="Enter a high mark"
              />

              <SdSelect
                rules="required"
                label="Comment"
                v-model="fastComment.comment.description"
              >
                <option v-for="comment in comments" :key="comment.id">
                  {{ comment.description }}
                </option>
              </SdSelect>
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import COMMENTS from '../../../graphql/comment/Comments.gql'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'
import { fetchUser } from '@/assets/js/app_info.js'

export default {
  comment: 'comment',
  data() {
    return {
      schoolId: null,
      userId: null,
      pageTitle: 'Fast Comment',
      skipQuery: true,
      comments: [],
      fastComment: {
        id: null,
        user: {
          id: null,
        },
        comment: {
          id: null,
          description: null,
        },
        lowMark: null,
        highMark: null,
      },
    }
  },
  apollo: {
    fastComment: {
      query: gql`
        query fastCommentQuery($id: ID!) {
          fastComment(id: $id) {
            id
            user {
              id
            }
            commentId
            comment {
              id
              description
            }
            lowMark
            highMark
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
    comments: {
      query: COMMENTS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    'fastComment.comment.description'(data) {
      const comment = this.comments.filter(
        (comment) => comment.description === data
      )[0]
      if (!comment) return

      this.fastComment.commentId = comment.id
    },
  },
  methods: {
    submit() {
      const commentId = this.fastComment.commentId
      const lowMark = parseFloat(this.fastComment.lowMark)
      const highMark = parseFloat(this.fastComment.highMark)

      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createFastComment(
                $userId: Int!
                $commentId: Int!
                $lowMark: Float!
                $highMark: Float!
              ) {
                createFastComment(
                  input: {
                    userId: $userId
                    commentId: $commentId
                    lowMark: $lowMark
                    highMark: $highMark
                  }
                ) {
                  fastComment {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              userId: this.userId,
              commentId: parseInt(commentId),
              lowMark: lowMark,
              highMark: highMark,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createFastComment.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/fast_comments`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateFastComment(
                $id: Int!
                $commentId: Int!
                $lowMark: Float!
                $highMark: Float!
              ) {
                updateFastComment(
                  input: {
                    id: $id
                    commentId: $commentId
                    lowMark: $lowMark
                    highMark: $highMark
                  }
                ) {
                  fastComment {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.$route.params.id),
              commentId: parseInt(commentId),
              lowMark: lowMark,
              highMark: highMark,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateFastComment.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/fast_comments`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchUser().then((user) => {
      this.userId = user.id
      this.schoolId = user.school_id
      this.$store.commit('setSubMenus', [
        {
          name: 'Fast Comments',
          route: `/school/${this.schoolId}/fast_comments`,
        },
      ])
    })
  },
}
</script>
